
import {For,Show,Match,Switch,createSignal, children, createMemo, ErrorBoundary} from 'solid-js';
import {StandardMenuItem} from 'Shared/frontend/menuPage/StandardMenuItem';
import {ImagelessMenuItem} from 'Shared/frontend/menuPage/ImagelessMenuItem';
import {StandardDefaultLayout} from 'Shared/frontend/layouts/StandardDefaultLayout';
import {Overlay} from 'Shared/frontend/overlayWidget/Overlay';
import {locateSubDocument} from 'Common/ViewUtils';
import {StickySubNav} from 'Shared/frontend/StickySubNav';
import {Location} from 'Common/config/PageConfigTypes';
import {PageProvider} from 'Shared/artists/PageProvider';
import { SpecialsPoster } from 'Shared/frontend/specialsPosters/SpecialsPoster';

export function MenuPage(props)
{
	const [currentLocation,setCurrentLocation] = createSignal();
	const [currentSpecial,setCurrentSpecial] = createSignal();

	const overlayItem = () => locateSubDocument(props.menu,currentLocation());
	const topSection = () => locateSubDocument(props.menu,currentLocation().slice(0,2));


	return (
		<PageProvider page={props.page}>
			<StandardDefaultLayout params={props.params} config={props.config} venue={props.venue} pageName={props.pageName} classes={props.htmlClasses} >
				<div class='anchor' id='top'></div>
			{/* CJ added section .content so enclosed fields inherit showcaseWidgets styling */}
			<section class='content'> 
				<Show when={props.menu.teaser}>
					<div class='teaser'>{props.menu.teaser}</div>
				</Show>

				<Show when={props.menu.title}>
					<h3 class='level1'>{props.menu.title}</h3>
				</Show>

				<Show when={props.menu.tagline}>
					<div class='tagline'>{props.menu.tagline}</div>
				</Show>

				<Show when={props.menu.details}>
					<div class='details' innerHTML={props.menu.details} />
				</Show>

				</section>

				<div class='menuGrid'>
					<For each={props.menu.items}>{ (item,index) => 
						<Show when={item.status=='published'}>
							<MenuItem 
								settings={props.venue.settings} item={item} menu={props.menu} 
								location={['items',index()]} setCurrentLocation={setCurrentLocation} 
							/>
						</Show>
					}</For>
				</div>

				<Show when={props.menu.sections && props.menu.sections.length > 0}>
					<For each={props.menu.sections}>{ (section,index) => 
						<DisplaySectionOrWidget settings={props.venue.settings} level={2} 
							section={section} 
							menu={props.menu}
							location={['sections',index()]}
							setCurrentLocation={setCurrentLocation} 
							specials={props.specials}
							setCurrentSpecial={setCurrentSpecial} 
						/>
					}</For>
				</Show>

				{/* Overlays: */}

				<Show when={currentLocation() != undefined}>
					<Overlay classes='menuItemOverlay' close={() => setCurrentLocation(undefined)}>
						<SectionWraps menu={props.menu} location={currentLocation()}>
							<MenuItem
								menu={props.menu}
								location={currentLocation()}
								settings={props.venue.settings}
								item={overlayItem()}
								productType={productTypeSelector(topSection().productType)}
							/>
						</SectionWraps>
					</Overlay>
				</Show>

				<Show when={currentSpecial() != undefined}>
					<Overlay classes='menuItemOverlay' close={() => setCurrentSpecial(undefined)}>
						<ErrorBoundary fallback={(err) => <div>Problem showing item</div>}> 
							<SpecialsPoster special={props.specials[currentSpecial()]} />
						</ErrorBoundary>  
					</Overlay>
				</Show>
			</StandardDefaultLayout>
		</PageProvider>
	);
}

/* Used in overlays and backend previews to add section wraps for styling */
export function SectionWraps(props)
{
	const selectors = () => {
		const selectors = [];
		const locationFront = [];
		let previousWasSections = false;

		for (const part of props.location) {
			locationFront.push(part);
			if (previousWasSections) {
				selectors.push(menuSelector(props.menu,locationFront));
			}
			previousWasSections = part == 'sections';
		}
		return selectors;
	};

	const content = children(() => props.children);

	return (
		<SectionWrapsInner selectors={selectors()} content={content()}>
		</SectionWrapsInner>
	);
}

function SectionWrapsInner(props)
{
	return (
		<Switch>
			<Match when={props.selectors.length > 0}>
				<div class={props.selectors[0]}>
					<SectionWrapsInner selectors={props.selectors.slice(1)} content={props.content}  />
				</div>
			</Match>
			<Match when={true}>
				{props.content}
			</Match>
		</Switch>
	);
}

function DisplaySectionOrWidget(props)
{
	return (
		<Switch>
			<Match when={props.section.widget != undefined}>
				<MenuWidget {...props} widget={props.section} />
			</Match>
			<Match when={true}>
				<DisplaySection {...props} />
			</Match>
		</Switch>
	);
}

function DisplaySection(props)
{
	return <section class={`${menuSelector(props.menu,props.location)} ${productTypeSelector(props.section.productType)}`}>
		<div class='anchor' id={menuSelector(props.menu,props.location)}></div>

		<Show when={props.section.teaser}>
			<div class='teaser'>{props.section.teaser}</div>
		</Show>

		<Show when={props.section.title}>
			<h3 class={`level${props.level}`}>{props.section.title}</h3>
		</Show>

		<Show when={props.section.tagline}>
			<div class='tagline'>{props.section.tagline}</div>
		</Show>

		<Show when={props.section.details}>
			<div class='details' innerHTML={props.section.details} />
		</Show>

		<div class='menuGrid'>
			<For each={props.section.items}>{ (item,index) => 
				<Show when={item.status=='published'}>
					<MenuItem settings={props.settings} item={item} menu={props.menu} location={[...props.location,'items',index()]} setCurrentLocation={props.setCurrentLocation} />
				</Show>
			}</For>
		</div>

		<Show when={props.section.sections && props.section.sections.length > 0}>
			<For each={props.section.sections}>{ (subsection,index) => 
				<DisplaySectionOrWidget settings={props.settings} level={props.level+1} 
					section={subsection} 
					menu={props.menu}
					location={[...props.location,'sections',index()]}
					setCurrentLocation={props.setCurrentLocation} 
					specials={props.specials}
					setCurrentSpecial={props.setCurrentSpecial} 
				/>
			}</For>
		</Show>
	</section> ;
}

export function MenuItem(props) 
{
	const style = () => props.settings.pages.menu.itemType;
	/* TODO We could be more efficient about calculating this... */
	const productType = () => calculateProductType(props.menu,props.location);
	const id = createMemo(() => menuSelector(props.menu,props.location));

	return (<>
		<Switch>
			<Match when={style()=='standard'}>
				<a href='' onclick={() => props.setCurrentLocation(props.location)} >
					<div id={id()} class='anchor' />
					<StandardMenuItem id={id()} item={props.item} productType={productType()} />
				</a>
			</Match>
			<Match when={style()=='imageless'}>
				<div>
					<div id={id()} class='anchor' />
					<ImagelessMenuItem id={id()} item={props.item} productType={productType()} />
				</div>
			</Match>
		</Switch>
	</>);
}

function MenuWidget(props)
{
	return (
		<Switch>
			<Match when={props.widget.widget=='buttonRow'}>
				<ButtonsRowWidget {...props} />
			</Match>
			<Match when={props.widget.widget=='specials'}>
				<SpecialsWidget {...props} />
			</Match>
		</Switch>
	);
}

function ButtonsRowWidget(props)
{
//XXX cf 'open in new window' open? ALSO make consistent between the sticky version and the non-sticky

	const buttonClass = b => {
		if (b.style=='food' || b.style=='drinks')
			return b.style;
		return '';
	};

	return (
		<Switch>
			<Match when={props.widget.sticky}>
				<StickySubNav>
					<For each={props.widget.buttons}>{(button,index) =>
						<button class={buttonClass(button)} onclick={() => location.href = button.url}>{button.label}</button>
					}</For>
				</StickySubNav>
			</Match>
			<Match when={true}>
				<div class='flexCenter'>
					<For each={props.widget.buttons}>{(button,index) =>
						<a class={`buttonWrap ${buttonClass(button)}`} href={button.url}>
							<button><div class='label'>{button.label}</div></button>
						</a>
					}</For>
				</div>
			</Match>
		</Switch>
	);
}


function SpecialsWidget(props)
{
//TODO insert title standard way + taglines etc

	return (
		<Show when={props.specials?.length > 0}>

			<section class='specials'>
				<div class='anchor' id='specials'></div>
				<h3 class='level2'>Specials</h3>
				<div class='posterGrid'>
					<For each={props.specials}>{(special,index) => 
						<a href='' onclick={() => props.setCurrentSpecial(index)} >
							<ErrorBoundary fallback={(err) => <div>Problem showing item</div>}>  
								<SpecialsPoster special={special} location={[index]} /> 
							</ErrorBoundary>	
						</a>
					}</For>
				</div>
			</section>
		</Show>
	);
}

export function calculateProductType(store,location:Location)
{
	if (store.productType!=undefined && store.productType!='mixed')
		return store.productType;

	let data = store;
	for (let i=0; i<location.length; i++) {
		data = data[location[i]];

		if (data.productType!=undefined && data.productType!='mixed')
			return data.productType;
	}

	return 'mixed';
}

export function productTypeSelector(title:string)
{
	return 'type__'+(title ?? '').replace(/\W/g, '');
}


/* Selectors used for sections, subsections and menu items. */
export function menuSelector(menu,location:Location)
{
	let subdoc = menu;
	let useSection = false;
	let useItems = false;
	let id = '';
	let sep = '';

	let i = 0;
	for (const e of location) {
		subdoc = subdoc[e];

		//XXX maybe capitalise words. Title will usually be capitalised OK I guess...
		if (useSection) {
			id += sep + capitalise(subdoc.title ?? ''+i).replace(/\W/g,'');
			sep = '.';
		}
		if (useItems) {
			id += sep + capitalise(subdoc.name ?? ''+i).replace(/\W/g,'');
			sep = '.';
		}

		useSection = e=='sections';
		useItems = e=='items' ;
		i++;
	}
	return id;
}

function capitalise(s:string)
{
	return s.split(/\s+/).map(word => 
		(word?.[0] ?? '').toUpperCase() + 
		(word.substring(1)?.toLowerCase() ?? ''))
	.join(' ');
}

