import { z } from 'zod';
import * as t from 'Shared/model/basic';
import {lineup} from 'Shared/model/lineup';


const eventParent = z.object({
	_id: t.id,
	siteId: t.id,
	slug: z.string(),
	name: z.string(),
	times: t.eventCalenderSettings,
	shortDescription: z.string().max(200),
	priceOptions: z.string().max(100),    //FIXME
	customPrice: z.string().max(32), 
	purchaseLink: t.url,
	//TODO rename to 'posterType'. There's an image and its different
	imageType: z.union([z.literal('auto'),z.literal('poster'),z.literal('photo')]),
	poster: t.image,
	/* The image is sometimes used to help construct the poster */
	imageDimensions: z.union([z.literal('landscape'),z.literal('portrait'),z.literal('square')]),
	image: t.image,		 //XXX better to put imageDimensions into image so both defined or undefined together
//	shareImage: t.image, 
	fbPosterSettings: t.posterSettings,
	facebookImage: t.image,
	instagramImage: t.image,
	task_announceFb: z.string().max(50),
	status: z.string().max(100),	//XXX can do better
	scheduleDate: t.dateTime, 
	announceDate: t.dateTime
})
.partial().required({
	_id: true,
	siteId: true,   //FIXME only in server I think
	slug: true,
	status: true
});

//TODO make these events strict. Currently cross-contamination

export const musicEvent = eventParent.extend({
	eventType: z.literal('music'), 
	musicPosterSource: z.string().max(16).optional(),  //FIXME
	hasHeadliner: z.boolean().optional(),
	lineup: lineup,
});

export type MusicEvent = z.infer<typeof musicEvent>;

export const socialEvent = eventParent.extend({
	eventType: z.literal('social'), 
	nonMusicDescription: z.string().max(200).optional(),
	nonMusicPosterSource: z.string().max(16).optional(),
});

export type SocialEvent = z.infer<typeof socialEvent>;

export const tvEvent = eventParent.extend({
	eventType: z.literal('tv'), 
	nonMusicDescription: z.string().max(200).optional(),
	nonMusicPosterSource: z.string().max(16).optional(),
});

export type TvEvent = z.infer<typeof tvEvent>;

export const eventDoc = z.discriminatedUnion('eventType',[musicEvent,socialEvent,tvEvent]);

export type EventDoc = z.infer<typeof eventDoc>;


/*
export class EventCollection
{
	schema = event;

//FIXME REQUIRED TO UPDATE CALENDAR!!
//	hooks:XXX,

//	indexes: XXXX

	images = {
		shareImage: imageSize.squareAllSizes,
		builtPoster: imageSize.squareAllSizes,
		photo: imageSize.landscape,
		image: imageSize.portrait07071AllSizes,
		facebookImage: imageSize.facebookBanner,
		instagramImage: imageSize.squareAllSizes
	}
}
//XXX Q:
// 1. Are these just a specs for what exists? 
//      Can I use them to create the images?
//      
*/
