import { z } from 'zod';
import * as t from 'Shared/model/basic';


//used for slider inside vertical sections/showcase
export const carouselItem = z.object({
	image: t.image,
	imageCaption: z.string(),
	itemTeaser: z.string(),
	itemTitle: z.string(),
	itemTagline: z.string(),
	addItemDetails:z.boolean(),
	itemDetails: z.string(),
	addItemLink:z.boolean(),
	itemLink: t.url,
	itemTarget_self:z.boolean(),
	itemLinkText: z.string(),
});





export const showcase = z.object({
	layout: z.enum([
		'carousel',
		'noImage',
		'imageRight',
		'imageLeft',
		'imageAbove',
		'imageBelow',
		'imageAsBackground',
		'gallery',
		'slideshow',
		'carouselAllEvents',
		'carouselMusic',
		'carouselNonMusic',
		'carouselSpecials'
		]),
	stickyButtons:z.boolean(),
	teaser: z.string(),
	title: z.string(),
	tagline: z.string(),
	addDetails:z.boolean(),
	area: z.string(),
	capacity: z.string(),
	details: z.string(),
	image: t.image,
	logo: t.image.optional(), // Used in parallax
	addLink:z.boolean(),
	link: t.url,
	target_self:z.boolean(),
	linkText: z.string(),
	items: z.array(carouselItem)
});

//showcase above is being implemented to replace space
export const space = z.object({
//	position: z.number()  FIXME remove these...

	title: z.string(),
	description: z.string(),
	capacity: z.number(),
	fee: z.string(),
	tags: z.any(), //FIXME
	numberPhotos: z.number(),
	photo: t.image,
	photo2: t.image,
	photo3: t.image,
	photo4: t.image
})
.strict()
.partial().required({
});







export const gallery = z.object({
//	position: z.number()  FIXME remove these...
	caption: z.string(),
	photo: t.image
})
.strict()
.partial().required({
});

export const document = z.object({
//	position: z.number()  FIXME remove these...
	name: z.string(),
	viewURL: z.string(),
	publish: z.boolean(),
	viewButton: z.string(),
	buttonLink: z.string(),
	editURL: z.string()
})
.strict()
.partial().required({
});

export const hire = z.object({
	_id: t.id,
	siteId: t.id,
	introduction: z.string(),
//	email: z.string(),		//TODO create email type
//	email2: z.string(),
showcase: z.array(showcase),
	space: z.array(space),
	gallery: z.array(gallery),
	document: z.array(document)
})
.partial().required({
	_id: true,
	siteId: true,   //FIXME only in server I think
	showcase: true,
	space: true,
	gallery: true,
	document: true
});

export type Hire = z.infer<typeof hire>;
